import Notification, {INotification,} from "../model/user/viewables/Notification";
import User from "../model/user/User";
import GetUser from "../wrapper/user/GetUser";
import GetNotification from "../wrapper/user/viewables/GetNotification";
import {default as AdminUserList} from "../model/admin/UserList";
import {default as SupervisorLeadList} from "../model/user/supervisor/LeadList";
import {default as SupervisorUserList} from "../model/user/supervisor/UserList";
import Sidebar from "../model/user/viewables/Sidebar";
import NotificationBell from "../model/user/viewables/NotificationBell";
import GetNotificationBell from "../wrapper/user/viewables/GetNotificationBell";
import GetSidebar from "../wrapper/user/viewables/GetSidebar";
import GeneralResponse from "../GeneralResponse";
import ChangeUsername from "../wrapper/user/change/ChangeUsername";
import ChangePassword from "../wrapper/user/change/ChangePassword";
import Programme from "../model/user/Programme";
import GetUserProgramme from "../wrapper/user/GetUserProgramme";
import Teams from "../model/team/Teams";
import GetTeams from "../wrapper/team/GetTeams";
import TaskList from "../model/team/TaskList";
import GetTaskList from "../wrapper/team/tasks/GetTaskList";
import {default as SupervisorGetUserList} from "../wrapper/user/supervisor/GetUserList";
import {default as SupervisorGetLeadList} from "../wrapper/user/supervisor/GetLeadList";
import ProgrammeTeamList from "../model/team/supervisor/ProgrammeTeamList";
import ProgrammeFormList from "../model/team/supervisor/ProgrammeFormList";
import ListTeams from "../wrapper/team/supervisor/ListTeams";
import ListForms from "../wrapper/team/supervisor/ListForms";
import ShareRegistryInformation from "../model/team/ShareRegistryInformation";
import GetShareRegistryInformation from "../wrapper/team/shares/GetShareRegistryInformation";
import {default as GetAdminUserList} from "../wrapper/admin/GetUserList";
import VideoPlaylists from "../model/user/viewables/VideoPlaylists";
import GetVideoPlaylists from "../wrapper/user/viewables/GetVideoPlaylists";
import AdminArticleList from "../model/admin/ArticleList";
import GetAllArticles from "../wrapper/admin/article/GetAllArticles";
import ArticleList from "../model/user/content/ArticleList";
import GetArticleList from "../wrapper/user/content/GetArticleList";

export class UserCache {
    readonly token: string;
    lastAccessed: Date;

    constructor(token: string) {
        this.token = token;
        this.lastAccessed = new Date();

        this.notification = new Map<string, Notification>();
    }

    //User
    private _user: User | undefined;
    private _programme: Programme | undefined;
    private _articleList: ArticleList | undefined;
    //User


    //Viewables
    private _sidebar: Sidebar | undefined;
    private _notificationBell: NotificationBell | undefined;
    private notification: Map<string, Notification>;
    //Viewables


    private _supervisorLeadList: SupervisorLeadList | undefined;
    private _supervisorUserList: SupervisorUserList | undefined;

    //Admin
    private _adminUserList: AdminUserList | undefined;
    private _adminArticleList: AdminArticleList | undefined;

    //Teams
    private _teams: Teams | undefined;
    private _videoPlaylists: VideoPlaylists | undefined;
    private _taskList: TaskList | undefined;
    private _shareRegistry: ShareRegistryInformation | undefined;

    private _supervisorTeamList: ProgrammeTeamList | undefined;
    private _supervisorFormList: ProgrammeFormList | undefined;

    //Teams

    get programme(): Promise<Programme | undefined> {
        return new Promise<Programme | undefined>(async (resolve) => {
            if (this._programme !== undefined && !this._programme.expired)
                return resolve(this._programme);

            let request = await GetUserProgramme.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._programme = request.additionalInformation;
            return resolve(this._programme);
        });
    }

    get user(): Promise<User | undefined> {
        return new Promise<User | undefined>(async (resolve) => {
            if (this._user !== undefined && !this._user.expired)
                return resolve(this._user);

            let request = await GetUser.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._user = request.additionalInformation;
            return resolve(this._user);
        });
    }

    get notificationBell(): Promise<NotificationBell | undefined> {
        return new Promise<NotificationBell | undefined>(async (resolve) => {
            if (
                this._notificationBell !== undefined &&
                !this._notificationBell.expired
            )
                return resolve(this._notificationBell);

            let request = await GetNotificationBell.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._notificationBell = request.additionalInformation;
            return resolve(this._notificationBell);
        });
    }

    get sidebar(): Promise<Sidebar | undefined> {
        return new Promise<Sidebar | undefined>(async (resolve) => {
            if (this._sidebar !== undefined && !this._sidebar.expired)
                return resolve(this._sidebar);

            let request = await GetSidebar.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._sidebar = request.additionalInformation;
            return resolve(this._sidebar);
        });
    }

    get teams(): Promise<Teams | undefined> {
        return new Promise<Teams | undefined>(async (resolve) => {
            if (this._teams !== undefined && !this._teams.expired)
                return resolve(this._teams);

            let request = await GetTeams.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._teams = request.additionalInformation;
            return resolve(this._teams);
        });
    }

    get videoPlaylists(): Promise<VideoPlaylists | undefined> {
        return new Promise<VideoPlaylists | undefined>(async (resolve) => {
            if (this._videoPlaylists !== undefined && !this._videoPlaylists.expired)
                return resolve(this._videoPlaylists);

            let request = await GetVideoPlaylists.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._videoPlaylists = request.additionalInformation;
            return resolve(this._videoPlaylists);
        });
    }

    get taskList(): Promise<TaskList | undefined> {
        return new Promise(async (resolve) => {
            if (this._taskList !== undefined && !this._taskList.expired)
                return resolve(this._taskList);

            let request = await GetTaskList.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._taskList = request.additionalInformation;
            return resolve(this._taskList);
        });
    }

    get articleList(): Promise<ArticleList | undefined> {
        return new Promise<ArticleList | undefined>(async resolve => {
            if (this._articleList !== undefined && !this._articleList.expired)
                return resolve(this._articleList);

            let request = await GetArticleList.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._articleList = request.additionalInformation;
            return resolve(this._articleList);
        });
    }

    get shareRegistry(): Promise<ShareRegistryInformation | undefined> {
        return new Promise<ShareRegistryInformation | undefined>(
            async (resolve) => {
                if (this._shareRegistry !== undefined && !this._shareRegistry.expired)
                    return resolve(this._shareRegistry);

                let request = await GetShareRegistryInformation.request(this.token);
                if (request.status !== 200) return resolve(undefined);
                this._shareRegistry = request.additionalInformation;
                return resolve(this._shareRegistry);
            }
        );
    }

    get supervisorUserList(): Promise<SupervisorUserList | undefined> {
        return new Promise<SupervisorUserList | undefined>(async (resolve) => {
            if (
                this._supervisorUserList !== undefined &&
                !this._supervisorUserList.expired
            )
                return resolve(this._supervisorUserList);

            let request = await SupervisorGetUserList.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._supervisorUserList = request.additionalInformation;
            return resolve(this._supervisorUserList);
        });
    }

    get supervisorLeadList(): Promise<SupervisorLeadList | undefined> {
        return new Promise<SupervisorLeadList | undefined>(async (resolve) => {
            if (
                this._supervisorLeadList !== undefined &&
                !this._supervisorLeadList.expired
            )
                return resolve(this._supervisorLeadList);

            let request = await SupervisorGetLeadList.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._supervisorLeadList = request.additionalInformation;
            return resolve(this._supervisorLeadList);
        });
    }

    get supervisorTeamList(): Promise<ProgrammeTeamList | undefined> {
        return new Promise<ProgrammeTeamList | undefined>(async (resolve) => {
            if (
                this._supervisorTeamList !== undefined &&
                !this._supervisorTeamList.expired
            )
                return resolve(this._supervisorTeamList);

            let request = await ListTeams.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._supervisorTeamList = request.additionalInformation;
            return resolve(this._supervisorTeamList);
        });
    }

    get supervisorFormList(): Promise<ProgrammeFormList | undefined> {
        return new Promise<ProgrammeFormList | undefined>(async (resolve) => {
            if (
                this._supervisorFormList !== undefined &&
                !this._supervisorFormList.expired
            )
                return resolve(this._supervisorFormList);

            let request = await ListForms.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._supervisorFormList = request.additionalInformation;
            return resolve(this._supervisorFormList);
        });
    }

    get adminUserList(): Promise<AdminUserList | undefined> {
        return new Promise<AdminUserList | undefined>(async (resolve) => {
            if (this._adminUserList !== undefined && !this._adminUserList.expired)
                return resolve(this._adminUserList);

            let request = await GetAdminUserList.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._adminUserList = request.additionalInformation;
            return resolve(this._adminUserList);
        });
    }

    get adminArticleList(): Promise<AdminArticleList | undefined> {
        return new Promise<AdminArticleList | undefined>(async (resolve) => {
            if (this._adminArticleList !== undefined && !this._adminArticleList.expired) return resolve(this._adminArticleList);

            let request = await GetAllArticles.request(this.token);
            if (request.status !== 200) return resolve(undefined);
            this._adminArticleList = request.additionalInformation;
            return resolve(this._adminArticleList);
        })
    }

    async getNotification(id: string): Promise<INotification | undefined> {
        if (
            this.notification.get(id) !== undefined &&
            !this.notification.get(id)?.expired
        )
            return this.notification.get(id);

        let notification = await GetNotification.request(id, this.token);
        if (notification.status !== 200) return undefined;
        this.notification.set(id, notification.additionalInformation);

        return notification.additionalInformation;
    }

    async updateUsername(username: string): Promise<GeneralResponse> {
        return await ChangeUsername.request(username, this.token);
    }

    async updatePassword(
        oldPassword: string,
        newPassword: string
    ): Promise<GeneralResponse> {
        return await ChangePassword.request(oldPassword, newPassword, this.token);
    }
}
import React from "react";
import {Route, Routes} from "react-router-dom";
import {Login} from "./components/authentication/Login";
import {Register} from "./components/authentication/Register";
import {Forgot} from "./components/authentication/forgot/Forgot";
import isAuthenticated from "./helpers/isAuthenticated";
import {Logout} from "./components/authentication/Logout";
import {UseForgot} from "./components/authentication/forgot/UseForgot";
import {Skeleton} from "./components/skeleton/Skeleton";
import {Notification} from "./components/user/viewables/Notification";
import {Profile} from "./components/user/Profile";
import {Username} from "./components/user/change/Username";
import {Password} from "./components/user/change/Password";
import {RegisterTeam} from "./components/team/create/RegisterTeam";
import {Teams} from "./components/team/Teams";
import {Tasks} from "./components/team/tasks/Tasks";
import {EmbeddedForm} from "./components/team/tasks/EmbeddedForm";
import {UserList} from "./components/user/supervisor/UserList";
import {LeadList} from "./components/user/supervisor/LeadList";
import {TeamList} from "./components/team/supervisor/TeamList";
import {FormList} from "./components/team/supervisor/FormList";
import {AssignMentor} from "./components/team/assign/AssignMentor";
import {AssignStudent} from "./components/team/assign/AssignStudent";
import {NotFound} from "./components/NotFound";
import {ShareRegistryList} from "./components/team/shares/ShareRegistryList";
import {ListUsers} from "./components/admin/ListUsers";
import {CreateAdmin} from "./components/admin/create/CreateAdmin";
import {CreateTeacher} from "./components/admin/create/CreateTeacher";
import {ResetYearly} from "./components/admin/ResetYearly";
import {AssignTeamLogo} from "./components/team/assign/AssignTeamLogo";
import {VideoTutorials} from "./components/user/viewables/VideoTutorials";
import {FormResults} from "./components/team/tasks/FormResults";
import {ArticleEditor} from "./components/admin/content/ArticleEditor";
import {ListArticles} from "./components/admin/content/ListArticles";
import {CreateArticle} from "./components/admin/content/CreateArticle";
import {RenameArticle} from "./components/admin/content/RenameArticle";
import {ContentList} from "./components/user/content/ContentList";
import {ReadContent} from "./components/user/content/ReadContent";
import {EditShareRegistry} from "./components/team/shares/EditShareRegistry";
import {ResetCache} from "./components/admin/ResetCache";
import {CreateShareRegistry} from "./components/team/shares/CreateShareRegistry";

export default class App extends React.Component<any, any> {
    render() {
        if (!isAuthenticated()) {
            return (
                <>
                    <Skeleton/>
                    <Routes>
                        <Route path={"/"} element={<Login/>}/>
                        <Route path={"/forgot"} element={<Forgot/>}/>
                        <Route path={"/forgot/:id"} element={<UseForgot/>}/>
                        <Route path={"/register"} element={<Register/>}/>

                        <Route path={"*"} element={<NotFound/>}/>
                    </Routes>
                </>
            );
        }

        return (
            <>
                <Skeleton/>
                <Routes>
                    <Route path={"/"} element={<Profile/>}/>
                    <Route path={"/content"} element={<ContentList/>}/>
                    <Route path={"/content/:id"} element={<ReadContent/>}/>
                    <Route path={"/videos"} element={<VideoTutorials/>}/>

                    <Route path={"/supervisor/list/users"} element={<UserList/>}/>
                    <Route path={"/supervisor/list/leads"} element={<LeadList/>}/>

                    <Route path={"/supervisor/list/teams"} element={<TeamList/>}/>
                    <Route path={"/supervisor/list/forms"} element={<FormList/>}/>

                    <Route path={"/team"} element={<Teams/>}/>
                    <Route path={"/team/tasks"} element={<Tasks/>}/>

                    <Route path={"/team/shareregistry"} element={<ShareRegistryList/>}/>
                    <Route path={"/team/shareregistry/create/:code"} element={<CreateShareRegistry/>}/>
                    <Route path={"/team/shareregistry/edit/:id"} element={<EditShareRegistry/>}/>

                    <Route path={"/team/form/:id"} element={<EmbeddedForm/>}/>
                    <Route path={"/team/form/:id/results"} element={<FormResults/>}/>
                    <Route path={"/team/assign/mentor"} element={<AssignMentor/>}/>
                    <Route path={"/team/assign/student"} element={<AssignStudent/>}/>
                    <Route
                        path={"/team/assign/logo/:code"}
                        element={<AssignTeamLogo/>}
                    />

                    <Route path={"/team/register"} element={<RegisterTeam/>}/>

                    <Route path={"/change/username"} element={<Username/>}/>
                    <Route path={"/change/password"} element={<Password/>}/>
                    <Route path={"/notification/:id"} element={<Notification/>}/>q

                    <Route path={"/admin/article/create"} element={<CreateArticle/>}/>
                    <Route path={"/admin/article/list"} element={<ListArticles/>}/>
                    <Route path={"/admin/article/rename/:id"} element={<RenameArticle/>}/>
                    <Route path={"/admin/article/edit/:id"} element={<ArticleEditor/>}/>

                    <Route path={"/admin/list-users"} element={<ListUsers/>}/>
                    <Route path={"/admin/create/admin"} element={<CreateAdmin/>}/>
                    <Route path={"/admin/create/teacher"} element={<CreateTeacher/>}/>
                    <Route path={"/admin/reset/yearly"} element={<ResetYearly/>}/>
                    <Route path={"/admin/reset/cache"} element={<ResetCache/>}/>

                    <Route path={"/logout"} element={<Logout/>}/>

                    <Route path={"*"} element={<NotFound/>}/>
                </Routes>
            </>
        );
    }
}
